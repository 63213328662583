/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onServiceWorkerUpdateReady = () => window.location.reload();

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, mainNavHeight = 200) {
    window.scrollTo({top: 0});
    if (location && location.hash) {
        setTimeout(function(){
            const item = document.getElementById(`${location.hash.replace('#', '')}`)
            if(item){
                window.scrollTo({
                    top: item.getBoundingClientRect().top - mainNavHeight,
                    behavior: "smooth",
                })
            }
        }, 750);
    }
    return true
}

export function onRouteUpdate( { location, prevLocation}) {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
        window.dataLayer.push({
            event: `gatsby-route-change`
        });
    }, 50);

    scrollToAnchor(location);
}
