import React from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import themeOptions from "../../.brew-cache/theme.json"

// Switch based on the activeTheme
let theme = '';

switch(themeOptions.activeTheme){
  case 'Robinsons - Accommodation Pubs':
  default:
      theme = require('./rob-brand-theme').default;
      break;
  case 'Robinsons - Non-accommodation Pubs':
      theme = require('./rob-pub-theme').default;
      break;
}

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}