import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"
import themeData from "./muiTheme.json"
import themeOptions from "../../../.brew-cache/theme.json"
import { modifyMui } from "../modifyMui"
import RobLogo from "../../../src/images/rob-family-logo-no-text.png"
import IILogo from "../../../src/images/indi-inns-brand.png"

const themeName = "Robinsons - Brand Theme"
const modifiedTheme = modifyMui(themeData, themeOptions)
let theme = createMuiTheme({ ...modifiedTheme, themeName })
theme = responsiveFontSizes(theme, { factor: 3 })

// Custom theme styles here or import them from wherever if they need to be broken

// Reusable

theme.headerBefore = {
  "&::before": {
    width: 60,
    height: 1,
    content: '""',
    display: "block",
    marginBottom: 16,
    backgroundColor: theme.palette.primary.main,
  },
}

// Global

theme.topNavTheme = {
  "&[open]": {
    opacity: 0,
  },
  "& .rob-header-logo": {
    position: "absolute",
    bottom: "calc(100% + 6px)",
    margin: 0,
    left: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.up("md")]: {
      bottom: "calc(100% + 12px)",
    },
  },
}

theme.bookingSplitTheme = {
  "& .MuiCardContent-root::after": {
    content: '""',
    display: "block",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: 40,
    zIndex: "1",
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: theme.texture,
    [theme.breakpoints.up("md")]: {
      height: 72,
    },
  },
  "& h3": {
    position: "relative",
    zIndex: "2",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: 50,
      left: "50%",
      transform: "translateX(-50%)",
      width: 310,
    },
  },
  "&::after": {
    content: '""',
    display: "block",
    height: 10,
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: theme.texture,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    [theme.breakpoints.up("md")]: {
      height: 24,
    },
  },
}

theme.subscribePageTheme = {
  "&::after": {
    content: '""',
    display: "block",
    height: 10,
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: theme.texture,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    [theme.breakpoints.up("md")]: {
      height: 24,
    },
  },
}

theme.footerTheme = {
  position: "relative",
  "&::before": {
    content: '""',
    display: "block",
    height: 24,
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: theme.texture,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
  },
}

// Blocks

theme.accordionTheme = {}

theme.accommodationBannerTheme = {
  "& .banner-content": {
    bottom: 120,
    letterSpacing: "0.008em",
    [theme.breakpoints.up("md")]: {
      bottom: 70,
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      fontSize: 32,
      letterSpacing: "-0.008em",
      lineHeight: "1.1",
      fontWeight: "400",
      [theme.breakpoints.up("md")]: {
        fontSize: 68,
        lineHeight: "1",
      },
    },
    ...theme.headerBefore,
  },
  "&::after": {
    content: '""',
    display: "block",
    width: "100vw",
    height: 24,
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: theme.texture,
    marginLeft: "50%",
    transform: "translateX(-50vw)",
  },
}

theme.accommodationIntroTheme = {
  padding: "22px 0",
  [theme.breakpoints.up("md")]: {
    padding: "108px 0",
  },
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontSize: 24,
    lineHeight: "2",
    letterSpacing: "0.06em",
    fontWeight: "600",
    fontFamily: theme.subHeaderFont,
    textTransform: "uppercase",
    [theme.breakpoints.up("md")]: {
      fontSize: 32,
      lineHeight: "1.5",
    },
    ...theme.headerBefore,
  },
  "& ul": {
    [theme.breakpoints.up("md")]: {
      marginTop: 17,
    },
  },
}

theme.accommodationFeaturedRoomsTheme = {
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontSize: 28,
    lineHeight: "1.8",
    letterSpacing: "0.06em",
    fontWeight: "600",
    fontFamily: theme.subHeaderFont,
    textTransform: "uppercase",
    marginBottom: 30,
    [theme.breakpoints.up("md")]: {
      fontSize: 48,
      lineHeight: "1",
    },
    ...theme.headerBefore,
  },
}

theme.accommodationListingTheme = {
  "&::before": {
    content: '""',
    display: "block",
    width: "100vw",
    height: 24,
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: theme.texture,
    marginLeft: "50%",
    transform: "translateX(-50vw)",
    marginBottom: 50,
    [theme.breakpoints.up("md")]: {
      marginBottom: 144,
    },
  },
  "&::after": {
    content: '""',
    display: "block",
    width: "100vw",
    height: 24,
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: theme.texture,
    marginLeft: "50%",
    transform: "translateX(-50vw)",
    marginTop: 50,
    [theme.breakpoints.up("md")]: {
      marginTop: 140,
    },
  },
}

theme.accommodationOtherRoomsTheme = {
  paddingTop: 56,
  paddingBottom: 56,
  [theme.breakpoints.up("md")]: {
    paddingTop: 70,
    paddingBottom: 194,
  },
  "& h2": {
    textTransform: "uppercase",
    letterSpacing: "0.06em",
    fontFamily: theme.subHeaderFont,
    fontSize: 32,
    lineHeight: "1.5",
    [theme.breakpoints.up("md")]: {
      fontSize: 40,
      lineHeight: "1.4",
    },
    ...theme.headerBefore,
  },
}

theme.bannerCalloutCtaTheme = {
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontWeight: "400",
    fontSize: 28,
    lineHeight: "1.15",
    padding: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: 68,
      lineHeight: 1,
      letterSpacing: "0.008em",
    },
    "&::before": {
      width: 60,
      height: 1,
      content: '""',
      display: "block",
      margin: "0 auto 16px",
      backgroundColor: theme.palette.primary.main,
    },
  },
}

theme.bannerMessageTheme = {
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    bottom: 144,
    fontWeight: "400",
    fontSize: 28,
    lineHeight: "1.15",
    padding: 0,
    [theme.breakpoints.up("md")]: {
      bottom: 66,
      fontSize: 68,
      lineHeight: 1,
      letterSpacing: "0.008em",
    },
    ...theme.headerBefore,
  },
  "&::after": {
    content: '""',
    display: "block",
    width: "100vw",
    height: 24,
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: theme.texture,
    marginLeft: "50%",
    transform: "translateX(-50vw)",
  },
}

theme.breakoutGalleryIntroTheme = {}

theme.blogPagePostsTheme = {
  "& h1": {
    fontSize: 32,
    letterSpacing: "-0.008em",
    lineHeight: "1.1",
    fontWeight: "400",
    [theme.breakpoints.up("md")]: {
      fontSize: 68,
      lineHeight: "1",
    },
    ...theme.headerBefore,
  },
}

theme.cardTheme = {
  "& .con-wrap": {
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      textTransform: "uppercase",
    },
  },
}

theme.cardRowTheme = {
  "&.full-width": {
    width: "calc(100vw - 18px)",
    marginLeft: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.up("lg")]: {
      width: "calc(100vw - 72px)",
    },
  },
  "&.has-background": {
    paddingTop: 75,
    paddingBottom: 75,
    position: "relative",
    "&::before": {
      position: "absolute",
      top: 0,
      left: "50%",
      bottom: 0,
      width: "100vw",
      transform: "translateX(-50vw)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      content: '""',
      display: "block",
    },
  },
  "& .card": {
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      "&::before": {
        content: '""',
        display: "block",
        width: 40,
        height: 1,
        marginBottom: 26,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}

theme.calloutCtaTheme = {
  marginTop: 24,
  marginBottom: 24,
  position: "relative",
  "& p": {
    fontFamily: theme.headerFont,
    fontWeight: "400",
  },
  "&::before, &::after": {
    content: '""',
    display: "block",
    height: 24,
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: theme.texture,
    position: "absolute",
    left: 0,
    right: 0,
  },
  "&::before": {
    top: -24,
    bottom: "100%",
  },
  "&::after": {
    bottom: -24,
    top: "100%",
  },
}

theme.contactBannerTheme = {
  "& h1": {
    fontSize: 32,
    letterSpacing: "-0.008em",
    lineHeight: "1.1",
    fontWeight: "400",
    [theme.breakpoints.up("md")]: {
      fontSize: 68,
      lineHeight: "1",
    },
    ...theme.headerBefore,
  },
}

theme.contactFormTheme = {
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontWeight: "normal",
    fontFamily: theme.subHeaderFont,
    letterSpacing: "0.045em",
    fontSize: 24,
    lineHeight: "1.2",
    [theme.breakpoints.up("md")]: {
      fontSize: 28,
    },
  },
}

theme.iconListIntroTheme = {
  padding: "22px 0",
  [theme.breakpoints.up("md")]: {
    padding: "108px 0",
  },
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontSize: 24,
    lineHeight: "2",
    letterSpacing: "0.06em",
    fontWeight: "600",
    fontFamily: theme.subHeaderFont,
    textTransform: "uppercase",
    [theme.breakpoints.up("md")]: {
      fontSize: 32,
      lineHeight: "1.5",
    },
    ...theme.headerBefore,
  },
}

theme.iconListTheme = {
  [theme.breakpoints.up("md")]: {
    marginTop: 17,
  },
}

theme.imageBreakdownTheme = {
  paddingTop: 34,
  paddingBottom: 34,
  [theme.breakpoints.up("md")]: {
    paddingTop: 74,
    paddingBottom: 140,
  },
  "& .title-wrap": {
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      textTransform: "uppercase",
      letterSpacing: "0.06em",
      fontSize: 32,
      lineHeight: "1.1",
      marginBottom: 50,
      fontFamily: theme.subHeaderFont,
      [theme.breakpoints.up("md")]: {
        fontSize: 40,
        lineHeight: "1.35",
        marginBottom: 40,
      },
      "&::before": {
        width: 60,
        height: 1,
        content: '""',
        display: "block",
        margin: "0 auto 16px",
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}

theme.imageBreakdownInnerTheme = {
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    textTransform: "uppercase",
    letterSpacing: "0.03em",
    fontSize: 18,
    lineHeight: 1.5,
    fontWeight: 600,
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
      lineHeight: 1.25,
    },
  },
}

theme.imageCaptionTheme = {
  paddingTop: 80,
  position: "relative",
  [theme.breakpoints.up("md")]: {
    paddingTop: 99,
  },
  "&::before": {
    content: '""',
    height: 24,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: theme.texture,
  },
}

theme.imageLinkSliderTheme = {
  "& h2": {
    fontSize: 28,
    lineHeight: "1.25",
    fontWeight: "400",
    fontFamily: theme.headerFont,
    [theme.breakpoints.up("md")]: {
      fontSize: 36,
    },
  },
  "&::after": {
    position: "absolute",
    content: '""',
    bottom: 50,
    left: "50%",
    marginLeft: "-51px",
    width: 102,
    height: 105,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: "url(" + RobLogo + ")",
  },
  "&.indi-inns": {
    "&::after": {
      backgroundImage: "url(" + IILogo + ")",
    },
  },
}

theme.introGalleryTheme = {
  marginTop: 75,
  marginBottom: 50,
  [theme.breakpoints.up("md")]: {
    marginTop: 160,
    marginBottom: 130,
  },
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontSize: 32,
    lineHeight: 1.2,
    fontWeight: "600",
    fontFamily: theme.subHeaderFont,
    textTransform: "uppercase",
    [theme.breakpoints.up("md")]: {
      // maxWidth: 360,
      fontSize: 48,
      lineHeight: 1.2,
      letterSpacing: "0.06em",
    },
    ...theme.headerBefore,
  },
}

theme.introGallerySplitTheme = {
  marginTop: 60,
  marginBottom: 90,
  [theme.breakpoints.up("md")]: {
    marginTop: 144,
    marginBottom: 95,
  },
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontSize: 24,
    lineHeight: "2",
    letterSpacing: "0.06em",
    fontWeight: "600",
    fontFamily: theme.subHeaderFont,
    textTransform: "uppercase",
    [theme.breakpoints.up("md")]: {
      fontSize: 32,
      lineHeight: "1.5",
    },
    ...theme.headerBefore,
  },
}

theme.introImageSliderTheme = {
  marginTop: 160,
  marginBottom: 90,
  [theme.breakpoints.up("md")]: {
    marginTop: 144,
    marginBottom: 95,
  },
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontSize: 24,
    lineHeight: "2",
    letterSpacing: "0.06em",
    fontWeight: "600",
    fontFamily: theme.subHeaderFont,
    textTransform: "uppercase",
    [theme.breakpoints.up("md")]: {
      fontSize: 32,
      lineHeight: "1.5",
    },
    ...theme.headerBefore,
  },
}

theme.fileListTheme = {
  backgroundImage: theme.texture,
  "& h2": {
    backgroundColor: theme.palette.secondary.main,
    width: "calc(100% + 24px)",
    margin: "-44px -12px 12px",
    fontWeight: "400",
    fontSize: 32,
    letterSpacing: "-0.008em",
    lineHeight: "1.9",
    [theme.breakpoints.up("md")]: {
      fontSize: 52,
      lineHeight: "1.15",
      paddingBottom: 50,
      paddingTop: 80,
      marginTop: -45,
    },
  },
  [theme.breakpoints.up("md")]: {
    padding: 45,
    "& .inner": {
      backgroundColor: theme.palette.secondary.main,
      padding: "45px 45px 12px",
    },
  },
}

theme.latestPostsTheme = {
  marginTop: 100,
  marginBottom: 110,
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    fontSize: 32,
    lineHeight: "1.4",
    letterSpacing: "0.06em",
    fontWeight: "600",
    fontFamily: theme.subHeaderFont,
    textTransform: "uppercase",
    marginBottom: 30,
    [theme.breakpoints.up("md")]: {
      fontSize: 54,
      lineHeight: "1",
      marginBottom: 40,
    },
    ...theme.headerBefore,
  },
}

theme.offerGridInnerTheme = {
  "& .con-wrap": {
    "& .day-tag": {
      left: "auto",
      fontSize: 16,
      backgroundColor: theme.palette.background.primary,
      fontFamily: theme.headerFont,
      [theme.breakpoints.up("md")]: {
        top: 0,
        left: "calc(50% + 72px)",
      },
    },
  },
  "&:nth-of-type(odd)": {
    [theme.breakpoints.up("md")]: {
      "& .con-wrap": {
        "& .day-tag": {
          left: 60,
        },
      },
    },
  },
}

theme.openingTimesTheme = {
  paddingTop: 48,
  paddingBottom: 48,
  position: "relative",
  [theme.breakpoints.up("md")]: {
    paddingTop: 80,
    paddingBottom: 80,
  },
  "&::before": {
    content: '""',
    display: "block",
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: theme.texture,
    position: "absolute",
    zIndex: "-1",
    top: 0,
    bottom: 0,
    width: "100vw",
    left: "50%",
    transform: "translateX(-50vw)",
  },
  "& h2": {
    ...theme.headerBefore,
    [theme.breakpoints.up("md")]: {
      "&::before": {
        display: "none",
      },
    },
  },
}

theme.pullquoteTheme = {
  "& .quote-block": {
    flexDirection: "column-reverse",
    "& .quote-text": {
      "&::before": {
        lineHeight: "0.75",
      },
    },
  },
}

theme.thinIntroTheme = {
  padding: "22px 0",
  [theme.breakpoints.up("md")]: {
    padding: "108px 0",
  },
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    textTransform: "uppercase",
    letterSpacing: "0.06em",
    fontFamily: theme.subHeaderFont,
    fontSize: 28,
    lineHeight: "1.7",
    fontWeight: "600",
    marginBottom: 32,
    [theme.breakpoints.up("md")]: {
      fontSize: 32,
      lineHeight: "1.5",
    },
    ...theme.headerBefore,
  },
  "& p": {
    letterSpacing: "0.032em",
  },
}

theme.thickIntroTheme = {
  padding: "22px 0",
  [theme.breakpoints.up("md")]: {
    padding: "108px 0",
  },
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    textTransform: "uppercase",
    letterSpacing: "0.06em",
    fontFamily: theme.subHeaderFont,
    fontSize: 28,
    lineHeight: "1.7",
    fontWeight: "600",
    marginBottom: 32,
    [theme.breakpoints.up("md")]: {
      fontSize: 32,
      lineHeight: "1.5",
    },
    ...theme.headerBefore,
  },
  "& p": {
    letterSpacing: "0.032em",
  },
}

theme.twoCardIntroTheme = {
  "& .tag": {
    ...theme.headerBefore,
  },
}

theme.twoColIntroTheme = {
  "&::before": {
    bottom: 90,
    zIndex: 1,
  },
  "&::after": {
    content: '""',
    display: "block",
    position: "absolute",
    bottom: 0,
    left: "50%",
    width: "100vw",
    transform: "translateX(-50vw)",
    height: 90,
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: theme.texture,
    zIndex: 0,
    [theme.breakpoints.up("md")]: {
      top: 66,
      bottom: 66,
      height: "auto",
    },
  },
  "& h1, & h2, & h3, & h4, & h5, & h6": {
    textTransform: "uppercase",
    fontSize: 28,
    lineHeight: "1.8",
    letterSpacing: "0.06em",
    fontFamily: theme.subHeaderFont,
    position: "relative",
    zIndex: 1,
    marginBottom: 30,
    fontWeight: "400",
    [theme.breakpoints.up("md")]: {
      fontSize: 40,
      letterSpacing: "1.2",
    },
    ...theme.headerBefore,
  },
  "& p, & a": {
    zIndex: 1,
  },
  "& .image-wrap": {
    padding: "12px 5px",
    zIndex: 1,
    [theme.breakpoints.up("md")]: {
      padding: 12,
    },
  },
}

export default theme
